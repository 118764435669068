import React, { useEffect } from "react";
import Footer from "components/Footer";

export default function Contact() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (window.jotformEmbedHandler) {
        window.jotformEmbedHandler(
          "iframe[id='JotFormIFrame-243132541618451']",
          "https://form.jotform.com/"
        );
      }
    };
  }, []);

  return (
    <>
      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
            <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-3">
              <div className="lg:col-span-1">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Get in Touch
                </h2>
                <p className="mt-4 leading-7 text-gray-600">
                  Need BIM & Digitalisation services? Reach out to our
                  team.
                </p>
              </div>
              <div id="jotform-embed" className="lg:col-span-2">
                <iframe
                  id="JotFormIFrame-243132541618451"
                  title="Onsite BIM Request (bimeco)"
                  onLoad={() => window.parent.scrollTo(0, 0)}
                  allow="geolocation; microphone; camera; fullscreen"
                  src="https://form.jotform.com/243132541618451"
                  frameBorder="0"
                  style={{
                    width: "100%",
                    height: "539px",
                    border: "none",
                  }}
                  scrolling="no"
                ></iframe>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
              <div>
                <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                  Locations
                </h2>
                <p className="mt-4 leading-7 text-gray-600">
                  Our local service teams are ready to help you.
                </p>
              </div>
              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                <div className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    Singapore
                  </h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    <p>
                      159 Sin Ming Road, Amtech Building Lobby 1
                      #05-03
                    </p>
                    <p>Singapore 575625</p>
                  </address>
                </div>
                <div className="rounded-2xl bg-gray-50 p-10">
                  <h3 className="text-base font-semibold leading-7 text-gray-900">
                    US, Delaware
                  </h3>
                  <address className="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    <p>131 Continental Dr Suite 305 Newark</p>
                    <p>DE, 19713</p>
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
